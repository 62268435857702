import { ApiVolopImportedType } from 'modules/listing/api/volop/types/ApiVolopImportedType';

export const VOLOP_IMPORTED_TYPE_MAP: Record<ApiVolopImportedType, string> = {
  DONORS_CHOOSE: 'DonorsChoose.org',
  GLOBAL_GIVING: 'GlobalGiving',
  VOLUNTEERMATCH: 'VolunteerMatch',
  MOVE_ON: 'MoveOn',
  POINTS_OF_LIGHT: 'Points of Light',
  AARP: 'AARP',
  DO_SOMETHING: 'DoSomething',
  NEWYORKCARES: 'New York Cares',
  VOLUNTEERGOV: 'Volunteer.gov',
  GRAPEVINE: 'Grapevine',
  TRANSFORMA: 'Transforma',
};
