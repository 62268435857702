import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SortType } from 'store/mainSearch/MainStoreMainSearchState';
import { AlgoliaSearchOptionsWithIndex } from 'utils/algolia/types/AlgoliaSearchOptionsWithIndex';

import { searchAlgoliaEventsOptions } from './searchAlgoliaEventsOptions';
import { searchAlgoliaInternshipsOptions } from './searchAlgoliaInternshipsOptions';
import { searchAlgoliaJobsOptions } from './searchAlgoliaJobsOptions';
import { searchAlgoliaOrgsOptions } from './searchAlgoliaOrgsOptions';
import { searchAlgoliaVolopsOptions } from './searchAlgoliaVolopsOptions';

type Args = {
  filtersById: SearchFiltersById;
  sort: SortType;
};

export function searchAlgoliaOptionsSwitch({
  filtersById,
  sort,
}: Args): AlgoliaSearchOptionsWithIndex {
  switch (filtersById.type) {
    case 'JOB':
      return searchAlgoliaJobsOptions({ filtersById, sort });
    case 'VOLOP':
      return searchAlgoliaVolopsOptions({ filtersById, sort });
    case 'EVENT':
      return searchAlgoliaEventsOptions({ filtersById, sort });
    case 'INTERNSHIP':
      return searchAlgoliaInternshipsOptions({ filtersById, sort });
    case 'ORG':
      return searchAlgoliaOrgsOptions({ filtersById, sort });
  }
}
