import type { SearchResponse } from '@algolia/client-search';
import { Dispatch } from '@reduxjs/toolkit';

import { searchAlgoliaOptionsWithSearchLocation } from 'modules/search/algolia/options/searchAlgoliaOptionsWithSearchLocation';
import { searchAlgoliaOptionsSwitch } from 'modules/search/algolia/options/switch/searchAlgoliaOptionsSwitch';
import { searchAlgoliaConfig } from 'modules/search/algolia/searchAlgoliaConfig';
import { SearchAlgoliaHit } from 'modules/search/algolia/types/SearchAlgoliaHit';
import { MainStoreSearchRadiusState } from 'store/ducks/searchRadius';
import { algoliaSearch } from 'utils/algolia/algoliaSearch';

import { MainStoreMainSearchState } from './MainStoreMainSearchState';
import {
  FetchPageSuccessAction,
  MainStoreMainSearchAction,
} from './mainSearch';

const fetchPageSuccessful = ({
  results,
}: {
  results: SearchResponse<SearchAlgoliaHit>;
}): FetchPageSuccessAction => ({
  type: 'SEARCH_FETCH_PAGE_SUCCESS',
  results,
});

let lastSearchTimestamp: number | undefined;

export function mainStoreFetchPage(pageIndex?: number) {
  return async function fetchPatchDispatch(
    dispatch: Dispatch<MainStoreMainSearchAction>,
    getState: () => {
      mainSearch: MainStoreMainSearchState;
      searchRadius: MainStoreSearchRadiusState;
    },
  ) {
    dispatch({ type: 'SEARCH_STARTED' });

    const state = getState();

    const timestamp = new Date().getTime();
    lastSearchTimestamp = timestamp;

    const results = await algoliaSearch<SearchAlgoliaHit>({
      config: searchAlgoliaConfig(),
      query: state.mainSearch.query,
      options: {
        ...searchAlgoliaOptionsWithSearchLocation({
          options: searchAlgoliaOptionsSwitch({
            filtersById: state.mainSearch.filters,
            sort: state.mainSearch.sort,
          }),
          searchLocation: state.mainSearch.searchLocation,
          radius: state.searchRadius.value,
        }),
        page:
          typeof pageIndex === 'undefined'
            ? state.mainSearch.pageIndex
            : pageIndex,
      },
    });

    // Check if a different search ran afterwords
    if (lastSearchTimestamp === timestamp) {
      dispatch(fetchPageSuccessful({ results }));
    }

    return results;
  };
}
